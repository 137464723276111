import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import Seo from '../components/seo';

const NotFoundPage = function () {
  return (
    <>
      <Seo title="404: Not found" />
      <div className=" lg:min-h-full flex flex-col lg:relative">
        <div className="flex-grow flex flex-col">
          <main className="flex-grow flex flex-col bg-white">
            <div className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
              <div className="flex-shrink-0 my-auto py-16 sm:py-32">
                <p className="text-sm font-semibold text-brand-blue-200 uppercase tracking-wide">
                  404 error
                </p>
                <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  Page not found
                </h1>
                <p className="mt-2 text-base text-gray-500">
                  Sorry, we couldn’t find the page you’re looking for.
                </p>
                <Link to="/">Click here to return to home</Link>
              </div>
            </div>
          </main>
        </div>
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <StaticImage
            src="../images/show-jumping.png"
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Show Jumping Horses"
            className="absolute inset-0 h-screen w-full object-cover"
          />
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
